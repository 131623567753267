<template>
    <v-form ref="form" lazy-validation @keyup.enter="validateInput">
        <p class="pb-10 mt-n5">Get 15 minutes for <strong>MAHALA</strong> when you register.</p>
        <p class="text-center pb-9 pl-2 pr-2 mt-n3 text-darkRed" v-show="formErrors">
            <v-icon size="18" class="pb-1 pr-1"> mdi-alert </v-icon><span class="font-weight-bold">{{ formErrors }}</span>
        </p>



        <p v-for="error in errors">
            <li color="red">{{ error }}</li>
        </p>

        <v-text-field prepend-inner-icon="mdi-account" variant="outlined" clearable v-model="firstName"
            :rules="firstNameRules" label="First Name" required></v-text-field>

        <v-text-field prepend-inner-icon="mdi-account" variant="outlined" clearable v-model="lastName"
            :rules="lastNameRules" label="Last Name" required></v-text-field>

        <v-text-field prepend-inner-icon="mdi-phone" variant="outlined" clearable v-model="cellphone" :counter="10"
            :rules="cellphoneRules" label="Cellphone" required></v-text-field>

        <v-text-field prepend-inner-icon="mdi-phone" variant="outlined" clearable v-model="mobileNumber" :counter="10"
            :rules="cellphoneRules" label="Whatsapp Number" required></v-text-field>

        <v-text-field prepend-inner-icon="mdi-lock" variant="outlined" clearable v-model="password"
        :rules="passwordRules" label="Password" :type="showPassword ? 'text' : 'password'"
        :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="showPassword = !showPassword"
        required>
        <template>
            <v-icon @click="showPassword = !showPassword">{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
        </template>
        </v-text-field>

      <!--   <p class="frmValidation float-left" :class="{ 'frmValidation--passed': password.length > 7 }"><i class="frmIcon fas"
                :class="password.length > 7 ? 'fa-check' : 'fa-times'"></i> Longer than 6 characters
        </p><br>
        <p class="frmValidation float-left" :class="{ 'frmValidation--passed': has_uppercase }"><i class="frmIcon fas"
                :class="has_uppercase ? 'fa-check' : 'fa-times'"></i> Has a capital letter</p><br>
        <p class="frmValidation float-left" :class="{ 'frmValidation--passed': has_lowercase }"><i class="frmIcon fas"
                :class="has_lowercase ? 'fa-check' : 'fa-times'"></i> Has a lowercase letter</p><br>
        <p class="frmValidation float-left" :class="{ 'frmValidation--passed': has_number }"><i class="frmIcon fas"
                :class="has_number ? 'fa-check' : 'fa-times'"></i> Has a number</p><br>
        <p class="frmValidation float-left" :class="{ 'frmValidation--passed': has_special }"><i class="frmIcon fas"
                :class="has_special ? 'fa-check' : 'fa-times'"></i> Has a special character</p><br> -->



        <v-text-field prepend-inner-icon="mdi-lock-check" variant="outlined" clearable
        v-model="passwordConfirm" :rules="passwordConfirmRules" label="Confirm Password" :type="showPassword ? 'text' : 'password'"
        :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="showPassword = !showPassword"
        required
        v-on:keyup.enter="validateInput"
        >
        <template>
            <v-icon @click="showPassword = !showPassword">{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
        </template>

        </v-text-field>
        <hr class="mt-10 mb-7 text-gray">
        <v-row class="justify-center mx-auto mb-10">
            <small class="signup-text">By signing up, you agree with our <router-link :to="{ name: 'TermsOfService' }"
                    class="text-pacificBlue text-decoration-none" exact>Terms of <br> Service</router-link> <router-link
                    :to="{ name: 'PrivacyPolicy' }" style="color: #0693e3; text-decoration: none" exact>Privacy
                    Policy</router-link>.
            </small>
        </v-row>
        <v-row class="justify-end py-3">
            <v-btn right class="mr-4 bg-pacificBlue text-white" :disabled="buttonLoading" @click="validateInput">
                <span v-if="buttonLoading">
                    <v-progress-circular :size="22" :width="2" class="text-pacificBlue" indeterminate></v-progress-circular>
                </span>
                <span v-else>Signup</span>
            </v-btn>
        </v-row>
    </v-form>
</template>

<script lang="ts">
import axios from "axios";
import { store } from "../store.js";


export default {
    data: () => ({
        store,
        firstName: "",
        firstNameRules: [(v) => !!v || "First Name is required"],
        lastName: "",
        lastNameRules: [(v) => !!v || "Last Name is required"],
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        cellphone: "",
        mobileNumber: "",
        showPassword: false,
        cellphoneRules: [
            (v) => !!v || "Cellphone is required",
            (v) =>
                (!!v && v.length == 10 && !isNaN(v)) ||
                "Cellphone must be 10 digits long",
        ],
        password: "",
        passwordRules: [
            (v) => !!v || "Password is required",
            (v) =>
                v.length > 6 || "Password must be at least 6 characters long",
           /*  (v) => /[a-z]+/.test(v) || "Password must have at least one lowercase character",
            (v) => /[A-Z]+/.test(v) || "Password must have at least one uppercase character",
            (v) => /[0-9]+/.test(v) || "Password must have at least one digit",
            (v) => /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(v) || "Password must be at least have 1 special character" */
        ],

        passwordConfirm: "",
        passwordConfirmRules: [(v) => !!v || "Please confirm your password"],
        errors: [],
        formErrors: "",
        buttonLoading: false,
        accountID: null,
        connectionRefused: false,
    }),

    methods: {
        validateInput() {
            this.errors = []

            if (this.newPassword != this.newPasswordConfirm) {
                this.errors.push("The two passwords do not match. Please try again")
            }
            if (this.errors.length > 0) {
                return true
            }
            let vm = this;
            let isValid = this.$refs.form
                .validate()
                .then(function (formSubmission) {
                    if (formSubmission.valid) {
                        vm.register();
                    }
                });
        },
        async register() {
            this.buttonLoading = true;
            let vm = this;
            let response = await axios
                .post("register/", {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    cellphone: this.cellphone,
                    mobile_number: this.mobileNumber,
                    password1: this.password,
                    password2: this.passwordConfirm,
                })
                .then(function (response) {
                    if (response.status === 201) {
                        vm.accountID = response.data.account_id;
                        vm.$router.push({
                            name: "RegisterDone",
                            query: {
                                account_id: vm.accountID,
                            },
                        });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        vm.formErrors = error.response.data.message;
                    } else {
                        vm.connectionRefused = true;
                    }
                    vm.buttonLoading = false;
                });
        },
    },
    watch: {
        password(password) {
            this.has_number = /\d/.test(password);
            this.has_lowercase = /[a-z]/.test(password);
            this.has_uppercase = /[A-Z]/.test(password);
            this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(password);
        }
    }
};
</script>

<style scoped>
.frmValidation {
    font-size: 13px;
}

.frmValidation--passed {
    color: #717b85;
}

.frmIcon {
    color: #EB0029;
}

.frmValidation--passed .frmIcon {
    color: #0fa140;
}
.signup-text {
    font-size: 16px;
}
</style>