<template>
    <v-row no-gutters class="position-relative">
        <div class="text-center">
            <v-snackbar :color="snackbarType" light="true" multi-line  timeout="1"
                transition="dialog-bottom-transition">
                <span>{{ snackbarResponse }}</span>
            </v-snackbar>
        </div>
    </v-row>
</template>
<script lang="js">
export default {
    props: {
        snackbarType: String,
        opensnackBar: Boolean,
        snackbarResponse: String
    }
}
</script>