<script>
import axios from "axios";
import { Buffer } from "buffer";
import { store } from "../store.js";

export default {
  props: ["message", "redirect"],
  data: () => ({
    store,
    whatsappOptIn: null,
    rewardsBalance: null,
    walletBalance: 0,
    userName: null,
    userInformationLoaded: false,
    connectionRefused: false,
    snackbarResponse: "",
    snackbarType: "error",
    openSnackBar: false,
    loading: true,
    tab: "overview"

  }),

  methods: {
    async getUserAccount() {
      try {
        this.getWalletBalance()
        this.getRewards()
        this.userInformationLoaded = true

      }
      catch {
        this.connectionRefused = true

      }
    },
    async getWalletBalance() {
      let vm = this
      const response = await axios.get("wallet/balance/").then(
        function (response) {
          if (response.status === 200) {
            vm.walletBalance = response.data.balance
          }
          if (response.status === 401) {
            //pass
          }
          if (response.status === 403) {
            //pass
          }

        }
      ).catch((error) => {
        if (error.response.status !== 500) {
          vm.snackbarResponse = error.response.data.message;
        }


      })
      this.loading = false
    },
    async getRewards() {
      let vm = this
      const response = await axios.get("rewards/").then(
        function (response) {
          if (response.status === 200) {
            vm.rewardsBalance = response.data.balance
          }
          if (response.status === 401) {
            //pass
          }

        }
      ).catch((error) => {
        vm.snackbarResponse = error.response.data.message;
        vm.showSnackBar()
      })
    },
    showSnackBar() {
      setTimeout((this.openSnackBar = true), 3000);
    },
    isDateInPast(date) {
      let today = new Date();
      let testDate = new Date(date);
      if (today <= testDate) {
        return false;
      }

      return true;
    },
    formartDate(date) {
      let formartedDate = new Date(date);
      return formartedDate.toLocaleDateString("en-us", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
    convertAccountIDToBase64(id) {
      let data = id.toString();
      let buff = new Buffer(data);
      let base64data = buff.toString("base64");
      return base64data;
    },
    payAccount(id) {
      let accountID = this.convertAccountIDToBase64(id);
      let url =
        "https://pay.toomuchwifi.co.za/uncapped/" + accountID + "/";
      window.location.href = url;
    },
    accessWallet() {
      let url = "https://wallet.toomuchwifi.co.za";
      window.location.href = url;
    },
    redeemReward() {

      this.$router.push({ name: "Reward" })
    },
    myUncappedAccount() {
      let url = "/accounts/uncapped";
      window.location.href = url;
    },
    myHotspotAccount() {
      let url = "/accounts/hotspot";
      window.location.href = url;
    },
    refreshPage() {
      this.connectionRefused = false;
      this.userInformationLoaded = false;
      this.getUserAccount();
    },
  },
  mounted() {
    this.getUserAccount();
  },
};
</script>

<template>
  <v-container>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto">

      <v-row v-if="!loading" class="justify-center" align="center">

        <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8">
          <div v-if="userInformationLoaded && !connectionRefused">
            <v-row align="center" class="justify-center mx-auto mt-3 mb-2">
              <v-col cols="12" xs="12" sm="12" md="12" lg="5" xl="5">
                <v-card class="window-card " @click="accessWallet()">

                  <v-card-item>
                    <v-col>
                      <p class="  card-title">WALLET</p>
                    </v-col>
                    <v-col>

                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col cols="auto" class="text-white">
                          <p class="card-subtitle">Available Balance</p>
                          <p class="font-weight-bold mt-n1">R{{ walletBalance }}</p>

                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="float-right">

                          <btn class="bg-tmwOrange button" @click="accessWallet()"><v-icon size="31"
                              class="text-black mb-1">mdi-chevron-right</v-icon></btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card-item>


                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5">
                <v-card class="window-card">
                  <v-card-item>
                    <v-col>
                      <p class="text-tmwOrange font-weight-bold card-title">REWARDS</p>
                    </v-col>
                    <v-col>

                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col cols="auto" class="text-white">
                          <p class="card-subtitle">Available Balance</p>
                          <p class="font-weight-bold mt-n1">R{{ rewardsBalance }}</p>

                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="float-right">
                          <v-btn :to="{ name: 'Reward' }" size="small" class="text-none bg-tmwOrange" color="#4f545c"
                            variant="flat">
                            Redeem
                          </v-btn>
                        </v-col>

                      </v-row>
                    </v-col>
                  </v-card-item>
                </v-card>

              </v-col>
            </v-row>
            <v-row no-gutters class="d-flex justify-center align-center my-5 mx-auto">

              <v-col cols="auto" class=" text-center">
                <v-btn class="bg-tmwOrange text-center button" density="compact" size="small" height="70" width="70"
                  :to="{ name: 'Bundles' }">

                  <v-icon size="50" class="pl-7">mdi-arrow-down-thin</v-icon>
                  <v-icon size="50" class="pr-7">mdi-arrow-up-thin</v-icon>
                </v-btn>
                <br>
                <span class="button-text">Buy Data</span>
              </v-col>
              <v-col cols="auto" class="text-center pl-5">
                <v-btn class="bg-tmwOrange text-center button" density="compact" size="small" height="70" width="70"
                  :to="{ name: 'LoadVoucher' }">

                  <v-icon size="55">mdi-cash</v-icon>
                </v-btn>
                <br>
                <span class="button-text">Load Voucher</span>
              </v-col>
            </v-row>
          </div>
        </v-col>


      </v-row>
    </v-col>


  </v-container>
</template>

<style scoped>
.window-card {
  box-sizing: border-box;
  background: #24292E;
  border: 1px solid #FAFAFA;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  align-content: center;

}

.button {
  border-radius: 50px;
  font-size: 25px;
  width: 50px;
  height: 40px;

}

.card-title {
  position: absolute;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  /* identical to box height */

  text-transform: uppercase;

  color: #F9B500;
}
</style>