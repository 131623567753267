<template>
  <v-row align="center" class="justify-center mt-2">
    <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6" class="mx-auto">
      <v-card class="tab-content">
        <v-tabs v-model="tab" fixed-tabs selected-class="bg-pacificBlue" class="tab-content">
          <v-tab value="LOGIN">Login</v-tab>
          <v-tab value="SIGNUP">Signup</v-tab>
        </v-tabs>
        <v-card-text>
          <v-window v-model="tab">
            <v-window-item value="LOGIN">
              <LoginForm redirect="Home" />
            </v-window-item>
            <v-window-item value="SIGNUP">
              <RegisterForm />
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">

export default {
  props: {
    message: {
      type: String, // Define the prop type
    },
    redirect: {
      type: String, // Define the prop type
    },
    tabDefault: {
      type: String, // Define the prop type,
      default: "LOGIN"
    }
  },
  data() {
    return {
      tab: this.tabDefault // Initialize tab with the default value from the prop
    };
  },
  watch: {
    tabDefault(newVal) {
      this.tab = newVal; // Update tab when tabDefault changes
    }
  }
}

</script>

<style scoped>
.tab-content {
  border-radius: 5px 5px 0px 0px;
  border: 0.5px solid #CCC;


}
</style>