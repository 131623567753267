<template>
    <v-overlay :modelValue=true class="align-center justify-center" style="z-index: 999999;">
        <v-progress-circular color="pacificBlue" indeterminate size="100"><strong>{{ loadingMessage }}</strong></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
    props: {
        loadingMessage: {
            type: String,
            required:false,
            default:"Loading"
        }
    },
    mounted(){
        
    }
}
</script>