<template>
    <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6" class="mx-auto">

            <v-card elevation="1" class="rounded-xl" flat>
                <v-toolbar style="color: white;" class="mr-4 bg-pacificBlue">
                    <v-toolbar-title>Login</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <LoginForm redirect='Home'/>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

