<template>
  <v-form ref="form" lazy-validation @keyup.enter="validateInput" class="pb-2">
    <p class="text-center pb-9 pl-2 pr-2 mt-3 text-red" v-show="formErrors">
      <v-icon size="18" class="pb-1 pr-1"> mdi-alert </v-icon><span class="font-weight-bold">{{ formErrors
      }}</span>
    </p>
    <v-text-field class="pt-2" prepend-inner-icon="mdi-phone" variant="outlined" clearable v-model="cellphone"
      :counter="10" :rules="cellphoneRules" label="Cellphone" type="text" required></v-text-field>

    <v-text-field class="pt-2" prepend-inner-icon="mdi-lock" variant="outlined" clearable v-model="password"
      :rules="passwordRules" label="Password" :type="showPassword ? 'text' : 'password'"
      :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="showPassword = !showPassword"
      required>
      <template>
        <v-icon @click="showPassword = !showPassword">{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
      </template>
    </v-text-field>
  
      
        <!-- <router-link :to="{ name: 'PasswordReset' }" class="text-pacificBlue text-decoration-none d-flex justify-end " exact>Forgot
          Password?</router-link>
        <v-btn right style="color: white;" class=" bg-pacificBlue d-flex justify-end" :disabled="buttonLoading" @click="validateInput">
          <span v-if="buttonLoading">
            <v-progress-circular :size="22" :width="2" class="bg-pacificBlue" indeterminate></v-progress-circular>
          </span>
          <span v-else>Login</span>
        </v-btn>
      
     -->
      <div class="login-buttons">
        <a href="/forgot-password" class="forgot-password">
          Forgot Password?
        </a>
        <button right style="color: white;" class="submit-button" :disabled="buttonLoading" @click="validateInput">
          <span v-if="buttonLoading">
            <v-progress-circular :size="22" :width="2" class="bg-pacificBlue" indeterminate></v-progress-circular>
          </span>
          <span v-else>Login</span>
        </button>
      </div>
    

    <p class="register-text">
      New customer?
      <router-link :to="{ name: 'Register' }" class="text-pacificBlue text-decoration-none" exact>Click
        here</router-link>
      to sign up.
    </p>
  </v-form>
</template>

<script lang="ts">
import axios from "axios";
import { store } from "../store.js";

export default {
  props: ["message", "redirect"],
  data() {
    return {
      cellphoneApp: null,
      passwordApp: null,
      store,
      buttonLoading: false,
      cellphone: null,
      redirectRoute:null,
      cellphoneRules: [
        (v) => !!v || "Cellphone is required",
        (v) =>
          (!!v && v.length == 10 && !isNaN(v)) ||
          "Cellphone must be 10 digits long",
      ],
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],

      formErrors: "",
      openSnackBar: false,
      connectionRefused: false,
      showPassword: false,
      tab: "login",

    }
  },

  methods: {
    validateInput() {
      let vm = this;
      let isValid = this.$refs.form
        .validate()
        .then(function (formSubmission) {
          if (formSubmission.valid) {
            vm.login();
          }
        });
    },
    async login() {

      let vm = this;
      this.buttonLoading = true;
      if (vm.cellphoneApp && vm.passwordApp) {
        vm.password = vm.passwordApp
        vm.cellphone = vm.cellphoneApp
      }
      let response = await axios
        .post("login/", {
          password: vm.password,
          cellphone: vm.cellphone,
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.store.isUserAuthenticated = true;

            vm.store.cellphone = vm.cellphone;
            if (vm.redirectRoute == 'voucher'){
              vm.$router.push({name:'LoadVoucher'})
            }
            else if (vm.redirectRoute == 'data-prices'){
              vm.$router.push({name:'Bundles'})
            }
            else if (vm.$route.meta.tab){
              vm.$router.push({ name: vm.redirect, query: { tab: vm.$route.meta.tab } });
            }
            else{
              vm.$router.push({ name: vm.redirect });
            }
            
          }
        })
        .catch((error) => {
          if (error.response) {
            vm.formErrors = error.response.data.message;
          } else {
            vm.connectionRefused = true;
          }
          vm.buttonLoading = false;
        });
    },
  },

  mounted() {
    this.details = ""
    try {
      this.details = decodeURI(this.$route.fullPath).split("?")[1].split('&')
      this.cellphoneApp = this.details[0].split("=")[1].replace(/['"]+/g, '')
      this.passwordApp = this.details[1].split("=")[1].replace(/['"]+/g, '')
      this.login()
    }
    catch {
      //pass
    }
    try{
      this.redirectRoute = this.details[3].split("=")[1].replace("/","").replace("/","")
    }
    catch{
    }

    if (this.message) {
      this.openSnackBar = true;

    }
  },
};
</script>

<style scoped>
.break {
  height: 1px;
  border-width: 0px;
  margin-top: 50px;
}
.ç{
  left: 200px
}
.login-buttons {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.forgot-password {
  font-size: .75em;
  font-weight: 500;
  color: #1899D1;
  margin-bottom: 2em;
}

.submit-button {
  width: 100%;
  margin: auto;
  background-color: #1899D1;
  color: white;
  border-radius: 3px;
  padding: 0.5em 0;
  font-weight: 600;
  box-shadow: 0px 2px 4px 0px rgba(24, 153, 209, 0.20);
}

.register-text {
  font-size: 1em;
  text-align: center;
  font-weight: 500;
  color: #666666;
  margin: 2em 0;
}

@media only screen and (min-width: 768px) {
  .submit-button {
    width: 10em;
    margin: 0 0 0 auto;
  }
}
</style>