import axios from "axios";
import generated from "../assets/img/lead_generated.png";
import installed from "../assets/img/lead_installed.png";
import payment from "../assets/img/payment_received.png";
import failed from "../assets/img/failed_installations.png";
var env = import.meta.env

import { HOTSPOT_NODE_HTML } from "./dummy.js";

const hotspotMixin = {
  methods: {
    hotspotDetect() {
      let vm = this;
      console.log("env.MODE", env.MODE)
      if (env.MODE === "development") {
        this.store.isConnectedToHotspot = true;
        this.store.macAddress = HOTSPOT_NODE_HTML.mac;
        if (HOTSPOT_NODE_HTML.mac === "") {
          vm.store.loading = false;
        }
        console.log("this.store.macAddress", this.store.macAddress);
      } else {
        axios
          .get("https://hotspot.toomuchwifi.co.za/node.html", {
            withCredentials: false,
          })
          .then(function (response) {
            vm.store.isConnectedToHotspot = true;
            const loggedInStatus = response.data["logged-in"];
            if (loggedInStatus === "yes") {
              vm.store.hotspotAuthenicated = true;
            }

            if (loggedInStatus === "no") {
              vm.store.hotspotAuthenicated = false;
            }
            if ("mac" in response.data) {
              vm.store.macAddress = response.data["mac"];
            } else {
              vm.store.loading = false;
            }

            vm.store.loading = false;
          })
          .catch((error) => {
            vm.store.isConnectedToHotspot = false;
            vm.store.macAddress = null;
            vm.store.loading = false;
          });
      }
    },

    async hotspotLogin() {
      let test = await axios.get("hotspot/login/");
      let vm = this;
      if (test.status === 200) {
        let password = encodeURIComponent(test.data.password);
        let cellphone = test.data.cellphone;
        let response = await axios
          .get(
            `https://hotspot.toomuchwifi.co.za/login?username=${cellphone}&password=${password}`,
            {
              withCredentials: false,
            }
          )
          .then(function (response) {
            const loggedInStatus = response.data["logged-in"];
            if (loggedInStatus === "yes") {
              console.log("Logged In");
              window.location.href = "/hotspot-success.html";
              vm.store.hotspotAuthenicated = true;
            }

            if (loggedInStatus === "no") {
              console.log("Not Logged In");
              vm.store.hotspotAuthenicated = false;
            }
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
    },

    async getHotspotDevice(deviceMac) {
      console.log("deviceMac", deviceMac);
      if (deviceMac === null) {
        this.store.loading = false;
        return;
      }
      let vm = this;
      if (this.swapSelected !== undefined) {
        this.swapSelected("LOAD");
      }
      // Define the URL with the deviceMac parameter
      const apiUrl = `/hotspot/device/${deviceMac}/`;
      // Make the GET request to the API
      await axios
        .get(apiUrl)
        .then((response) => {
          // Check the response status code
          if (response.status === 200) {
            // Handle success
            vm.allocation = response.data.allocation;
            vm.device = response.data.device;
            if (vm.calculateTimeRemaining) {
              vm.calculateTimeRemaining();
            }
          } else {
            // Handle any errors or non-200 status codes
            throw new Error(
              `Failed to fetch device information: ${response.data}`
            );
          }
          this.store.loading = false;
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          throw new Error(`Request error: ${error.message}`);
        });
    },

    async hotspotMacLogin(macAddress) {
      let vm = this;
      if (vm.store.hotspotAuthenicated) {
        return;
      }
      this.connecting = true;

      let response = await axios
        .get(
          `https://hotspot.toomuchwifi.co.za/login?username=${macAddress}&password=${macAddress}`,
          {
            withCredentials: false,
          }
        )
        .then(function (response) {
          vm.hotspotDetect();
          vm.connecting = false;
          window.location.href = "/hotspot-device-success.html";
        })
        .catch((error) => {
          vm.connecting = false;
          return Promise.reject(error);
        });
    },

    checkStatus(status, img) {
      switch (status) {
        case "Installation Requested":
          return img ? generated : "Installation Requested";
          break;
        case "Payment Received":
          return img ? payment : "Payment Received";
          break;
        case "Installed":
          return img ? installed : "Installed";
          break;
        case "Failed Installation":
          return img ? failed : "Failed Installation";
          break;
      }
    },
    selectStatus(status, items) {
      if(items) {
        return items.filter((item) => item.status === status);
      }
    },
    getProductPrice(price) {
      return Math.round(price * 1.15);
    },
    checkPlanName(xxxx) {
      switch (xxxx) {
        case "5Mbps":
          return "Basic Plan"
            break;
        case "10Mbps":
          return "Standard Plan"
            break;
        case "20Mbps":
          return "Premium Plan"
            break;
      }
    },
  },
};

export default hotspotMixin;
