<script> 
import axios from "axios";
import { store } from "../store.js";

export default {
    data: () => ({
        store,
        message: "",
        buttonLoading: false,
        fullName: "",
        fullNameRules: [(v) => !!v || "Full Name is required"],
        cellphone: "",
        cellphoneRules: [
            (v) => !!v || "Cellphone is required",
            (v) =>
                (!!v && v.length == 10 && !isNaN(v)) ||
                "Cellphone must be 10 digits long",
        ],
        sameNumber: true,
        whatsappNumber: "",
        whatsappNumberRules: [
            (v) =>
                v.length == 0 ||
                (v.length == 10 && !isNaN(v)) ||
                "Cellphone must be 10 digits long",
        ],

        communities: null,
        community: null,
        community_id: null,
        contactTime: null,
        reason: null,
        reason_id: null,
        reasons: null,

        formErrors: "",
        serverResponse: "",
        postFailed: false,
        connectionRefused: false,
        dialog: false,
        submitted: false,
        reference: null
        
    }),

    methods: {
        validateInput() {
            let vm = this;
            let isValid = this.$refs.form
                .validate()
                .then(function (formSubmission) {
                    if (formSubmission.valid) {
                        vm.submitTicket();
                    }
                });
        },
        async submitTicket() {
            if (this.sameNumber){
              this.whatsappNumber = this.cellphone;
            }
            this.buttonLoading = true;
            let vm = this;
            let response = await axios
                .post("support/", {
                    full_name: this.fullName,
                    cellphone: this.cellphone,
                    community: this.community_id.toString(),
                    message: this.message,
                    reason: this.reason_id,
                    whatsapp_number: this.whatsappNumber,
                    request_source: "portal",
                    contact_time: this.contactTime
                })
                .then(function (response) {
                    if (response.status === 201) {
                        vm.buttonLoading = false;
                        vm.submitted = true;
                        vm.reference = response.data.uuid;
                    }
                    if (response.status === 202) {
                        vm.formErrors = response.data.message;
                        vm.buttonLoading = false;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        vm.formErrors = error.response.data.message;
                    } else {
                        vm.connectionRefused = true;
                    }
                    vm.buttonLoading = false;
                });
        },
        async getCommunities() {
            let vm = this;
            let response = await axios
                .get("support-communities/")
                .then(function (response) {
                    if (response.status === 200) {
                        vm.communities = response.data.communities;
                        vm.reasons = response.data.reasons;
              
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        vm.formErrors = error.response.data.message;
                    } else {
                        vm.connectionRefused = true;
                    }
                });
        },
        getCommunityNames() {
            let names = [];
            this.communities.forEach((element) => {
                names.push(element.name);
            });
            return names;
        },
        setCommunityID() {
            this.communities.forEach((element) => {
                if (element.name == this.community) {
                    this.community_id = element.id;
                    return;
                }
            });
        },
        getReasonNames() {
            let names = [];
            this.reasons.forEach((element) => {
                names.push(element.name);
            });
            return names;
        },
        setReasonID() {
            this.reasons.forEach((element) => {
                if (element.name == this.reason) {
                    this.reason_id = element.id;
                    return;
                }
            });
        },
        refreshPage() {
            this.connectionRefused = false;
            if (this.communities === null || this.reasons === null) {
                this.getCommunities();
            }
        },
    },
    mounted() {
        this.getCommunities();
    },
    watch: {
      sameNumber(val) {
        if (val) {
            this.whatsappNumber = this.cellphone;
        }
      },
    }
};
</script>

<template>
  <v-row no-gutters v-if="submitted">
      <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="mx-auto">
        <div
          class="col-md-12 text-center mx-auto pt-16 pb-10 text-blackPanther"
          >
          <v-icon size="90"> mdi-check-circle-outline </v-icon>
          <h2 class="pa-5 pt-8 text-pacificBlue">
              Ticket Captured!
          </h2>
          <p class="pt-8" id='font'>
              Thank you for your feedback. <a :href="`/ticket/status/${reference}`" style="color: #0393e3;"><b>Track the status of your ticket</b></a>
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters v-else>
   
      <v-col cols="12" sm="12" md="12" lg="11" xl="6" class="mx-auto">
        <div class="text-center mt-n10 ">
          <img src="../assets/img/contact_us_rafiki.svg" alt="" width="300">
        </div>
        <p class="text-center text-h5 title mt-n8" v-show="!connectionRefused">
          Customer Support
        </p>
        <p class="mt-8 mb-3 subtitle">Please fill in the form below to create a <br> ticket and our customer support team will contact you.</p>
        <br />
        <p
          class="text-centers pl-2 pr-2 text-darkRed"
          v-show="formErrors"
        >
          <v-icon size="18" class="pb-1 pr-1"> mdi-alert </v-icon><span class="font-weight-bold">{{ formErrors }}</span>
        </p>
        <div v-if="communities && reasons && !connectionRefused">
          
          <v-form ref="form" lazy-validation class="form">

            <v-text-field
              class="support-field"
              prepend-inner-icon="mdi-phone"
              variant="outlined"
              clearable
              v-model="fullName"
              :counter="10"
              :rules="fullNameRules"
              label="Full Name"
              required
              @input="(v) => !!v || 'Full Name is required'"
            ></v-text-field>

            <v-text-field
              class="support-field"
              v-if="!store.isUserAuthenticated"
              prepend-inner-icon="mdi-phone"
              variant="outlined"
              clearable
              v-model="cellphone"
              :counter="10"
              :rules="cellphoneRules"
              label="Cellphone"
              required
            ></v-text-field>

            <v-autocomplete
              class="support-field"
              prepend-inner-icon="mdi-map-marker"
              variant="outlined"
              v-model="community"
              :items="getCommunityNames()"
              :change="setCommunityID()"
              :rules="[(v) => !!v || 'Community is required']"
              label="Community"
              required
              filled
            ></v-autocomplete>

            <v-autocomplete
              class="support-field"
              prepend-inner-icon="mdi-chat-question"
              variant="outlined"
              v-model="reason"
              :items="getReasonNames()"
              :change="setReasonID()"
              :rules="[(v) => !!v || 'Reason for contact is required']"
              label="Reason for Contact"
              required
              
            ></v-autocomplete>

            <!-- <v-col cols="12" sm="12">
                <p class="text-h6 text-md-h6 text-lg-h6 text-pacificBlue">Best time to contact you ?</p>
            </v-col> -->
            <!-- <v-col cols="12" sm="12" md="8"> -->
                <v-select v-model="contactTime" label="Contact Time" variant="outlined" class="w-100 support-field"
                    prepend-inner-icon="mdi-clock"
                    :rules="[(v) => !!v || 'Contact time is required']"
                    :items='["Any Time", "Weekday Mornings", "Weekday Afternoons", "Weekdays Evenings", "Weekends"]'>
                </v-select>
            <!-- </v-col> -->

            <v-textarea
              class="support-message"
              prepend-inner-icon="mdi-message-text"
              variant="outlined"
              clearable
              auto-grow
              v-model="message"
              label="Message"
              rows="3"
             
            ></v-textarea>

            <v-checkbox density="compact" class="support-field" v-model="sameNumber" color="success">
                <template v-slot:label>
                    Same Number for WhatsApp
                </template>
                
            </v-checkbox>
           <v-text-field
              class="support-field"
              v-if="!store.isUserAuthenticated && !sameNumber"
              prepend-inner-icon="mdi-phone"
              variant="outlined"
              clearable
              v-model="whatsappNumber"
              :counter="10"
              :rules="whatsappNumberRules"
              label="Whatsapp Number"
             
            ></v-text-field>

            <v-row class="justify-end pt-3 button-container">
              <v-btn
                right

                class="mr-4 bg-pacificBlue text-white mb-4"
                :disabled="buttonLoading"
                @click="validateInput"
              >
                <span v-if="buttonLoading">
                  <v-progress-circular
                  :size="22"
                  :width="2"
                  class="text-pacificBlue"
                  indeterminate
                  ></v-progress-circular>      
                </span> 
                <span v-else>Submit</span>
              </v-btn>
            </v-row>
            
          </v-form>
          <div v-if="serverResponse">{{ serverResponse }}</div>
          <!-- <v-divider class="mt-6"></v-divider> -->
          <!-- <p class="text-center pa-5">
            
            <router-link :to="{ name: 'TicketStatus' }" class="text-pacificBlue text-decoration-none" exact>Click
              here</router-link>
            to check the status of your ticket.
          </p> -->
        </div>
        <div v-else-if="connectionRefused">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mx-auto text-center pt-16">
              <v-icon size="100">mdi-emoticon-sad-outline</v-icon>
              <p class="page-title" style="padding-bottom: 40px">Oops! Connection Lost</p>
              <p>Please check your internet connection and try again</p>
              <v-btn @click="refreshPage" size="large" class="bg-pacificBlue text-white font-weight-bold rounded-pill mt-12" >Try Again</v-btn>
            </v-col>
          </v-row>
        </div>
        
        <div
        v-else
        class="d-flex flex text-center justify-center align-center"
        height="250"
        >
        <v-progress-circular
        :size="50"
        class="text-pacificBlue"
        indeterminate
        ></v-progress-circular>
      </div>
      
    </v-col>
  </v-row>
  <v-row class="d-flex justify-end align-end ">
 
    <!-- <v-col class="justify-end">
      <div  v-if="dialog" 
>
        <v-dialog
          v-model="dialog"
          width="auto"
          content-class="center-dialog"
          
        >
       
         <v-row class="justify-end align-end">
          <v-btn icon dark flat size="v-small" class="close-dialog-button mt-n7 " @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
         <v-row class="justify-end">
          <img src="../assets/img/mobile_new_feature_alert.svg" alt="" width="400" class="mb-n4 text-center">
        </v-row>
          
          <v-row class="justify-end pr-9">
            <v-btn class="bg-grey text-white mt-n5 dialog-button" size="x-small" :to="{ name: 'TicketStatus'}">check the new feature</v-btn>
          </v-row>
         
        </v-dialog>
      </div>
    </v-col> -->
  </v-row>

</template>

<style scoped>
.title {
  left: calc(50% - 174px/2);

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  color: #1A1A1A;

}
.subtitle {
 
  left: calc(50% - 310px/2);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  text-align: center;

  color: #333333;
}
.close-dialog-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}

.v-dialog__content {
  display: none;
}

.dialog .v-dialog__content {
  display: block;
}
.dialog-button {
  font-size: 8px;
}

.support-field {
  margin-bottom: .5em;
}

@media only screen and (min-width: 768px) { 
  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .support-field {
    width: 48%;
    flex: unset;
  }

  .support-message {
    width: 100%;
  }

  .button-container {
    width: 100%;
    padding-bottom: 2em;
  }
  .button-container button {
    width: 11em;
  }
}
</style>