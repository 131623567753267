<template>
<v-row class="text-center"> 
      <v-col class="mt-11 mx-auto" cols="12" lg="6" md="6" sm="4">
        <img src="../assets/img/done.png" width="68">

        <div class="content mx-6 mt-9">
            <p class="text-h6 mb-5"><strong>Your installation has been rescheduled </strong></p>
            <span>
              We have just shared a link with you on WhatsApp. Please take a moment to check it and
              confirm your availability for the new date and time.
            </span>
            <v-divider class="mt-12 mb-5"></v-divider>
        </div>

        <v-row>
            <v-col>
                <v-btn
                class="bg-pacificBlue text-white text-weight-bold mt-8"
                width="184"
                @click="goNext">
                Done
            </v-btn>
            </v-col>
        </v-row>
      </v-col>
</v-row>
</template>
<style scoped>

</style>


<script>
import axios from "axios";
import { store } from "../store.js";


export default {
    components: {

  },
    data() {
    return {
      ref:'',
    };
  },
    methods: {
      
       
    },
    mounted(){
      
      this.ref=this.$route.params.ref
    }
}

</script>