<template>
  <footer class="footer">
    <div class="footer-links">
      <a href="/products">Products</a>
      <a href="/support">Support</a>
      <a href="/request">Request Installation</a>
    </div>
    <p> Copyright © TooMuchWifi {{ new Date().getFullYear() }}</p>
  </footer>
</template>
<script>
import { store } from "../store";

export default {
  data:() => ({
    store
  }),
  mounted() {
    console.log(store)
  }
}
</script>


<style scoped>
  footer {
    width: 100%;
    background: #24292E;
    color: white;
    text-align: center;
    padding: 1.5em 2em;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }

  a {
    color: #ffffff;
    margin-bottom: .5em;
    font-size: .75em;
  }

  p {
    font-size: .75em;
    font-weight: 600;
  }
  .footer-links a:hover {
    color: #f9b500;
  }

  @media only screen and (min-width: 768px) { 
    footer {
      display: flex;    
      align-items: baseline;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    .footer-links {
      max-width: 300px;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
    }

    a {
      margin-bottom: 0;
      font-size: .875em;
    }
  }
</style>
