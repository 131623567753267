<template>
    <v-container>
       
        
        <!-- <div class="text-center pb-9" align-items="center">
            <h1 class="page-title font-weight-bold text-body-1">Ticket Status</h1>
        </div> -->
        <div class="mt-n13 ">
            <v-row class="justify-center" >
                    <v-col cols="10" sm="4"  lg="10" xl="10">
                        <v-text-field


                        label="Enter ticket reference. e.g RZMYYKVT"
                        v-model="reference" 
                        variant="outlined"
                        :rules="referenceRules"
                        density="compact"
                 
                        ></v-text-field>
                    </v-col>
                    
                <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                    <v-btn size="small" flat class=" mt-1 button-size" @click="submitForm()"><v-icon size="32">mdi-magnify</v-icon></v-btn>
                </v-col>
            </v-row>
        </div>
     
        <div  v-if="ticketId" class="text-center pb-9 " align-items="center">
            <p class="body-text">See below the status of your ticket:</p>
            <!-- <div v-if="ticketId"> 
                {{ ticketId }}
            </div> -->
        </div>
        <div class="d-flex justify-start pb-9" align-items="start">
            <v-timeline truncate-line="both" side="end" align="start">
                <v-timeline-item v-for="status in statusList" :key="status"
                  :dot-color="status.state === 'Closed' ? 'grey' : 'tmwGreen'"
                  size="small"
                >

                <p class="timeline-text">  {{ status.state }} {{  formatDate(status.date) }} </p>
                </v-timeline-item>
            </v-timeline>
        </div>
        <v-responsive max-width="1100" class="text-center">
            <v-divider ></v-divider>
        </v-responsive>
        
        <div v-if="ticketId" class="text-center mt-10">
            <p class="mb-5 body-text">If you have additional queries and supporting documents, please enter them below</p>
            <v-form ref="form" class="mt-10">
                <v-responsive max-width="1100" class="text-center">
                    <v-divider ></v-divider>
                    <v-textarea v-model="notes" label="Message" prepend-inner-icon="mdi-message-processing" variant="outlined"></v-textarea>
                </v-responsive>
                <v-responsive class=" mb-2 mt-5 " max-width="1100">
                    <v-file-input v-model="images" class="" :rules="[(v) => !!v || 'Image is required']"
                        accept="image/png, image/jpeg, image/bmp" append-inner-icon="mdi-camera"
                        variant="outlined" label="Upload image">
                    </v-file-input>
                </v-responsive>
                <v-responsive max-width="1115">

                    <v-col class="text-end">
                        <v-btn class="bg-tmwBlue " @click="addContents()">Send</v-btn>
                    </v-col>
                </v-responsive>
                
            </v-form>

        </div>
        <!-- <LoadingOverlay v-if="loading"/> -->
    </v-container>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {

            loading:true,
            reference:"",
            ticketId: null,
            referenceRules:[
                (v) => !!v || "reference is required",
            ],
            notes: "",
            images: [],
            statusList: []
        }

    },
    methods: {
        async submitForm() {
            let vm = this
            vm.loading = true
            try {
                
                const response = await 

                axios.post(`ticket/status/movement?reference=${this.reference}`)
                .then(function(response) {
                    if(response.status === 200) {
                        vm.ticketId = response.data.ticket_id;
                        vm.statusList = response.data.status_list
                        
                        
                    }
                })
            }catch(error) {
               
            }
        },

        async getFileInBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        async addContents() {
            const encodedImages = [];
            for (let image of this.images) {
                try{
                    let res = await this.getFileInBase64(image);
                    encodedImages.push(String(res));
                }catch (error) {
                   
                }
            }
            const data = {
                image: encodedImages,
                ticket_id: this.ticketId,
                notes: this.notes
            };
            try {

                const response = await axios.post('ticket/contents/', data);
                if(response.status === 200) {
                    
                }
            }catch (error) {
                
            }
            this.loading = false
                
        },
        formatDate(date) {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },
        

    },



}
</script>

<style scoped>
.searchBar {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.timeline-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #666666;
}
.body-text {
 
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;

/* or 21px */
    text-align: center;

    color: #333333;
}
.button-size {
    font-size: 9px
}
.text-format {
    text-transform: lowercase;
    font-weight: 600;
  }
</style>